<template>
  <div class="ended">
    <div class="d-flex flex-column align-center text-center">
      <h3>{{ $t('dashboard.pages.myAccount.deleteTitle') }}</h3>
      <p class="py-5">{{ $t('dashboard.pages.myAccount.deleteDesc') }}</p>
      <AppButton @click="handleClick" color="secondary" :loading="loading">{{ $t('dashboard.pages.myAccount.deleteConfirm') }}</AppButton>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { consoleError } from 'xpermeet-lib';

import { USER, NOTIFICATION } from '@/constants/modules';
import { TOAST_TYPE } from '@/constants/toast';

export default {
  name: 'DeleteAccount',
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions(USER, ['deleteAccount']),
    ...mapActions(NOTIFICATION, ['showToastNotification']),
    async handleClick() {
      this.loading = true;

      try {
        await this.deleteAccount();
        this.$router.push('/logout');
      } catch (err) {
        consoleError(err);
        this.showToastNotification({ body: this.$t('error.accountDeleteFailed'), config: { type: TOAST_TYPE.ERROR } });
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.ended {
  height: 100%;
}
</style>

