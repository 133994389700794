var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ended" }, [
    _c(
      "div",
      { staticClass: "d-flex flex-column align-center text-center" },
      [
        _c("h3", [
          _vm._v(_vm._s(_vm.$t("dashboard.pages.myAccount.deleteTitle"))),
        ]),
        _c("p", { staticClass: "py-5" }, [
          _vm._v(_vm._s(_vm.$t("dashboard.pages.myAccount.deleteDesc"))),
        ]),
        _c(
          "AppButton",
          {
            attrs: { color: "secondary", loading: _vm.loading },
            on: { click: _vm.handleClick },
          },
          [_vm._v(_vm._s(_vm.$t("dashboard.pages.myAccount.deleteConfirm")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }